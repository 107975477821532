$green: #008061;
$darkGreen: #004d3a;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "open-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  transition: 0.4s ease all;
}

a {
  text-decoration: none;
}

body {
  overflow-x: hidden;
}

.mobileMenuContainer {
  width: 100%;
  height: 100%;
  background-color: $green;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  li {
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    padding: 20px 10px 20px 40px;
    font-weight: bold;
    list-style-type: none;

    @media screen and (max-width: 450px) {
      font-size: 16px;
      padding: 20px;
    }
  }

  .mobileMenuHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    img {
      @media screen and (max-width: 450px) {
        width: 200px;
      }
    }
  }
}

.greenBtn {
  color: white;
  background-color: $green;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0.75em 1.25em;
  border-radius: 0.25em;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: $darkGreen;
  }
}

.textButton {
  color: $green;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
}

.largeHeadline {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 72px;
  margin: 18px 0;
  line-height: 70px;
  color: #4a4a4a;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 44px;
  }
}

.smallSubhead {
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  color: #4a4a4a;
  margin: 18px 0;
}

.smallHeadline {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  color: #4a4a4a;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
  }
}

.smallDescription {
  font-size: 20px;
  margin: 20px 0;
}

.white {
  color: white;
}

.textCentre {
  text-align: center;
}

.bodyText {
  font-size: 16px;
  color: #4a4a4a;
  line-height: 30px;
}

.navContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: rgba($color: #000000, $alpha: 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  &.navScrolled {
    padding: 7px;
    background-color: rgba($color: #000000, $alpha: 1);
  }

  header {
    width: 1280px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }

    .navLogo {
      width: 230px;

      &.navLogoScrolled {
        width: 170px;
      }
    }

    .mobileMenu {
      display: none;

      @media screen and (max-width: 950px) {
        display: block;
      }
    }

    nav {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      position: relative;

      @media screen and (max-width: 950px) {
        display: none;
      }

      .searchBox {
        position: absolute;
        right: 0;
        bottom: -125%;
        padding: 0.75em 4em 0.75em 0.75em;
        width: 275px;
        text-align: left;
        outline: none;
        transform: translateY(-10px);
        opacity: 0;
        pointer-events: none;

        &.searchBoxShow {
          transform: translateY(0);
          opacity: 1;
          pointer-events: all;
        }
      }

      .navSearchBox {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .productsDropdown {
        position: absolute;
        left: 50%;
        right: 50%;
        top: 67px;
        transform: translate(-50%);
        width: 700px;
        height: 325px;
        background-color: white;
        z-index: 5;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        // display: grid;
        // grid-template-columns: 1fr 1.5fr;
        // grid-template-rows: auto;

        .productDetailsRight {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
          padding: 20px;
          gap: 20px;

          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .productDetailsImg {
              width: 168px;
              margin-bottom: 10px;
              height: 85px;
            }

            h5 {
              color: $green;
              font-size: 16px;
              text-align: center;
              font-weight: bold;
            }
          }
        }

        .leftLinks {
          height: 100%;
          background-color: $green;

          .productLink {
            text-transform: uppercase;
            color: white;
            list-style-type: none;
            padding: 20px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
              color: $green;
              background-color: white;
            }
          }
        }
      }

      .navLink {
        text-transform: uppercase;
        font-size: 16px;
        color: white;
        cursor: pointer;
        position: relative;
        user-select: none;

        .caretContainer {
          width: 15px;
          height: 15px;
        }

        &.navLinkActive {
          color: $green;
        }

        &:hover {
          color: $green;
        }
      }
    }
  }
}

.homeHero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-image: url("./assets/Homepage-Banner_R1.jpg");

  height: calc(100vw * (9 / 16));
  transition: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    height: 500px;
  }

  div {
    width: 1280px;

    @media screen and (max-width: 1300px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
}

.contactHero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-image: url("./assets/contact.png");

  height: calc(60vw * (9 / 16));
  transition: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    height: 500px;
  }

  &.pageNotFound {
    background-image: url("./assets/search-banner_R1.png");
  }

  div {
    width: 1280px;

    @media screen and (max-width: 1300px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
}

.homeInfo {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 75px;

  @media screen and (max-width: 720px) {
    padding: 25px;
  }

  div {
    width: 1280px;
  }
}

.questionsBanner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./assets/Tryton-OilfieldRentals.png");
  background-size: cover;
  padding: 175px 0;

  div {
    width: 1280px;

    @media screen and (max-width: 1300px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
}

footer {
  width: 100%;
  background-color: #252627;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .disclaimer {
    font-size: 16px;
    color: #ffffff80;
  }

  .footerLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    color: #ffffff80;
    cursor: pointer;
    margin-bottom: 50px;

    @media screen and (max-width: 600px) {
      flex-direction: column;

      span {
        display: none;
      }
    }

    p {
      font-size: 16px;
      color: #ffffff80;

      &:hover {
        color: #ffffff;
      }
    }
  }

  .phoneNum {
    font-size: 16px;
    color: #ffffff80;
    cursor: pointer;
    border-bottom: 1px solid #5c5c5c;
    padding: 50px;
    margin-bottom: 50px;
    width: 750px;
    text-align: center;

    &:hover {
      color: #ffffff;
    }

    @media screen and (max-width: 900px) {
      width: 80%;
    }
  }

  .socialMediaLinksContainer {
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    .socialMediaBubble {
      width: 40px;
      height: 40px;
      display: flex;
      border-radius: 50%;
      cursor: pointer;

      align-items: center;
      justify-content: center;
      background-color: rgba($color: #ffffff, $alpha: 0.5);
    }
  }
}

.margin30 {
  margin: 30px 0;
}

.contactAccordionContainer {
  width: 1280px;
  margin: 30px auto;

  @media screen and (max-width: 1300px) {
    width: 80%;
  }

  @media screen and (max-width: 800px) {
    width: 90%;
  }
}

.accordion {
  background-color: #94a3ae;
  border-radius: 0;
  text-transform: uppercase;

  .ant-collapse-header-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  .ant-collapse-expand-icon {
    color: white;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
    border-radius: 0;
  }
}

.contactBlock {
  border-radius: 0;
  background-color: #f0f0f0;
  padding: 10px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  padding-left: calc(1280px - 950px);

  @media screen and (max-width: 1300px) {
    padding-left: 100px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: 1fr;
    padding-left: 10px;
  }

  .contactItem {
    padding: 10px;

    .contactName {
      font-weight: bold;
      margin-bottom: 15px;
      text-transform: capitalize;
    }

    .contactTitle {
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    .iconInfoContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}

.clickableLink {
  cursor: pointer;
  &:hover {
    color: $green !important;
  }
}

.aboutUsContainer {
  width: 1280px;
  margin: 100px auto;

  @media screen and (max-width: 1300px) {
    width: 80%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
}

.greenTextHighlight {
  color: $green;
  font-weight: 600;
}

.productPageHero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-image: url("./assets/Services-Tryton-banner_R1.png");

  height: calc(60vw * (9 / 16));
  transition: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    height: 500px;
  }

  div {
    width: 1280px;

    @media screen and (max-width: 1300px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
}

.sideBySideImg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0;
  width: 100%;
  height: calc(40vw * (9 / 16));
  overflow: hidden;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  img {
    width: 50%;

    @media screen and (max-width: 720px) {
      width: 100%;
    }
  }
}

.subProductPageHero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  height: calc(60vw * (9 / 16));
  transition: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &.multiStageFrac {
    background-image: url("./assets/Homepage-Banner3_R1.jpg");
  }

  &.conventionalTools {
    background-image: url("./assets/Services-Tryton-banner_R1.png");
  }

  @media screen and (max-width: 1200px) {
    height: 500px;
  }

  div {
    width: 1280px;

    @media screen and (max-width: 1300px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 90%;
    }
  }
}

.productPageInfo {
  margin: 4em 0;
  padding-right: 40px;
  display: grid;
  grid-template-columns: 0.65fr 1.5fr;
  grid-template-rows: auto;
  gap: 8em;

  @media screen and (max-width: 950px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    padding: 40px;
    margin: 40px 0;
  }

  .secondaryMenu {
    background-color: #4a4a4a;
    width: 100%;
    height: fit-content;

    p {
      border-bottom: 1px solid #ffffff80;
      padding: 20px;
      font-weight: bold;
      text-align: right;
    }
  }
}

.navLink {
  text-transform: uppercase;
  font-size: 16px;
  color: white;
  cursor: pointer;
  position: relative;
  user-select: none;

  .caretContainer {
    width: 15px;
    height: 15px;
  }

  &.navLinkActive {
    color: $green;
  }

  &:hover {
    color: $green;
  }
}

.underConstructionMobileInfo {
  padding: 20px 40px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: white;
}
